import PropTypes from "prop-types";
import * as React from "react";

const Bar = ({ progress, animationDuration }) => (
  <div
    style={{
      background: "#FFF",
      height: "4px",
      left: 0,
      marginLeft: `${(-1 + progress) * 100}%`,
      position: "fixed",
      top: 0,
      transition: `margin-left ${animationDuration}ms linear`,
      width: "100%",
      zIndex: 103143293829,
    }}>
    <div
      style={{
        display: "block",
        height: "100%",
        opacity: 1,
        position: "absolute",
        right: 0,
        transform: "rotate(3deg) translate(0px, -4px)",
        width: 100,
      }}
    />
    {/* <Box position="absolute">
      <Spinner ml="3" size="xl" color={"blue.500"} />
    </Box> */}
  </div>
);

Bar.propTypes = {
  animationDuration: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
};

export default Bar;
