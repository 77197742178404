import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import Cookie from "js-cookie";
import { useMemo } from "react";

let apolloClient;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(
      ({ message, locations, path }) => {}
      // ToastsStore.error(message)
      //  console.log("")
    );

  if (networkError) {
    // console.log(`[Network error]: ${networkError}`)
  }
});

// https://api.learntific.com/graphql
// https://api.learntific.com/graphql

// const httpLink = new HttpLink({
//   uri: "https://api.learntific.com/graphql",
//   credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
// });

// const httpLink = new HttpLink({
//   uri: `${process.env.NEXT_PUBLIC_ENDPOINT}`,
//   credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
// });

const authLink = setContext(async (request, { headers }) => {
  // const token = await localStorage.getItem("x-auth-token");
  const token = await Cookie.get("x-auth-token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const httpLink = createUploadLink({
  uri: `${process.env.NEXT_PUBLIC_ENDPOINT}`,
});
// const httpLink = createUploadLink({ uri: `https://api.learntific.com/graphql` });

const link = ApolloLink.from([errorLink, authLink, httpLink]);

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: link,
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
