import { useNProgress } from "@tanem/react-nprogress";
import PropTypes from "prop-types";
import React from "react";
import Bar from "./Bar";
import Container from "./Container";
// import { Spinner, Box } from '@chakra-ui/core'

const Progress = ({ isAnimating }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  });

  return (
    <Container isFinished={isFinished} animationDuration={animationDuration}>
      <Bar progress={progress} animationDuration={animationDuration} />

      {/* <Box position="absolute">
        <Spinner size="sm" color="blue.500" />
      </Box> */}
    </Container>
  );
};

Progress.propTypes = {
  isAnimating: PropTypes.bool.isRequired,
};

export default Progress;
