import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import "@vime/core/themes/default.css";
import "@vime/core/themes/light.css";
import Analytics from "analytics";
import "emoji-mart/css/emoji-mart.css";
import "firebase/analytics";
import firebase from "firebase/app";
import "keen-slider/keen-slider.min.css";
import PlausibleProvider from "next-plausible";
import { DefaultSeo } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { RecoilRoot } from "recoil";
import { AnalyticsProvider } from "use-analytics";
import Progress from "../components/UI/Progress";
import { useApollo } from "../graphql/apolloClient";
import { CustomTheme } from "./../Helpers/Theme";
import "./../public/base.css";
import "./../public/bootstrap.css";
import "./../public/deck.css";
import "./../public/embla.css";
import "./../public/global.css";
import "./../public/reset.css";
import "./../public/theme.css";
import "./../public/trix.css";

// initialize firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIRESTORE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIRESTORE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIRESTORE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIRESTORE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  });
}

function App(props: any) {
  const router = useRouter();
  const { Component, pageProps } = props;
  const apolloClient = useApollo(pageProps.initialApolloState);

  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const handleRouteStart = (url) => {
      setLoading(true);
    };

    const handleRouteEnd = (url) => {
      setLoading(false);
    };

    router.events.on("routeChangeStart", handleRouteStart);
    router.events.on("routeChangeComplete", handleRouteEnd);
    router.events.on("routeChangeError", handleRouteEnd);
    // setLoading(true)

    // If the component is unmounted, unsubscribe
    // from the event with the`off` method:
    // return () => {
    //   router.events.off('routeChangeStart', handleRouteChange)
    // }
  }, []);

  /* Initialize analytics & load plugins */
  const analytics = Analytics({
    app: "coyani",
    plugins: [
      {
        name: "coyani-plugin",
        track({ payload }) {
          // console.log("handle track");
        },
        page({ payload }) {
          // console.log("handle track");
        },
      },
    ],
  });

  useEffect(() => {
    router.prefetch("/login");
    router.prefetch("/classes/enrolled");
    router.prefetch("/notifications");
    firebase.analytics();
  }, []);

  return (
    <>
      <Progress isAnimating={loading} />
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <script src="https://source.zoom.us/1.8.5/lib/vendor/jquery.min.js" />
      </Head>
      <DefaultSeo
        titleTemplate="%s - Learntific"
        title="Everything you need to host cohort-based classes - Learntific"
        description="We're building the future of interactive learning. Learning has never been this much immersive and engaging."
        canonical="https://learntific.com"
        openGraph={{
          type: "website",
          title: "Everything you need to host cohort-based classes - Learntific",
          description: "We're building the future of interactive learning. Learning has never been this much immersive and engaging.",
          images: [
            {
              url: "/og-image.png",
              width: 1200,
              height: 630,
            },
            {
              url: "/og-image.png",
              width: 520,
              height: 520,
            },
          ],
        }}
        twitter={{
          handle: "@learntific",
          site: "@learntific",
          cardType: "summary_large_image",
        }}
      />
      <PlausibleProvider domain="learntific.com">
        <RecoilRoot>
          <ApolloProvider client={apolloClient}>
            <ChakraProvider resetCSS theme={CustomTheme}>
              <AnalyticsProvider instance={analytics}>
                <Component {...pageProps} />
              </AnalyticsProvider>
            </ChakraProvider>
          </ApolloProvider>
        </RecoilRoot>
      </PlausibleProvider>
    </>
  );
}

export default App;
