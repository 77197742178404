import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "20em", // 320px
  md: "30em", // 480px
  lg: "48em", // 768px
  xl: "64em", // 1024px
  xxl: "90em", // 1440px
});

export const CustomTheme = extendTheme({
  components: {
    Container: {
      baseStyle: {
        px: { base: 6, lg: 4 },
        maxW: { md: "90vw", xxl: "84vw" },
      },
    },
    Menu: {
      parts: {},
    },
    Button: {
      baseStyle: {
        fontWeight: "700",
        transition: "all .3s ease",
        outline: "1px dotted transparent",
        minH: "52px",
        borderRadius: "14px",
        _hover: {
          textDecoration: "none",
        },
        _focus: (props: any) => ({
          boxShadow: "none",
          outline: `1px solid`,
          outlineColor: "white",
          outlineOffset: "2px",
          outlineWidth: "1px",
          outlineStyle: "dotted",
        }),
      },
      variants: {
        link: {},
        outline: (props: any) => ({
          borderWidth: "2px",
          borderColor: props.colorScheme + ".500",
          _hover: {
            // bg: props.colorScheme + ".500",
            // color: "white",
            // borderColor: props.colorScheme + ".500",
          },
        }),
      },
    },
  },
  // ...theme
  fonts: {
    heading:
      'Satoshi,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    body: 'Satoshi,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    mono: "Menlo, monospace",
  },

  fontSizes: {
    xs: "0.65rem",
    sm: "0.85rem",
    md: ".9rem",
    lg: "1rem",
    xl: "1.2rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  breakpoints,
  colors: {
    // ...theme.colors,
    black: "#070707",
    blue: {
      50: "#cdcdcd",
      100: "#acacac",
      200: "#838383",
      300: "#5a5a5a",
      400: "#303030",
      500: "#070707",
      600: "#060606",
      700: "#050505",
      800: "#040404",
      900: "#020202",
    },
    yellow: {
      50: "#fffecc",
      100: "#fffeaa",
      200: "#fffe80",
      300: "#fffd55",
      400: "#fffd2a",
      500: "#fffc00",
      600: "#d5d200",
      700: "#aaa800",
      800: "#807e00",
      900: "#555400",
    },
    green: {
      50: "#f5ffcc",
      100: "#eeffaa",
      200: "#e6ff80",
      300: "#ddff55",
      400: "#d5ff2a",
      500: "#ccff00",
      600: "#aad500",
      700: "#88aa00",
      800: "#668000",
      900: "#445500",
    },
    light: {
      50: "white",
      100: "white",
      200: "white",
      300: "white",
      400: "white",
      500: "white",
      600: "white",
      700: "white",
      800: "white",
      900: "white",
    },
    brand: {
      primary: "#005EE9",
      secondary: "#F1B500",
    },
  },
});
